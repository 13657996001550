import React from 'react'
import Select from "react-select"
export default function SelectComp({options,placeholder,onChange,defaultValue}) {

    const colourStyles2 = {
        option: (provided,state) => {
            return {
              backgroundColor: "#fff",
              backgroundColor: state.isFocused ? "#f5f5f5" : null ,
              color: "red",
              color: state.isFocused ? "#262626 !important" : null,
              fontSize: "14px",
              padding: "3px 20px",
              cursor: "pointer",
              lineHeight: "1.42857143"
            };
          },
      };

  return (
    <Select
      options={options}
      placeholder={placeholder}
      styles={colourStyles2}
      isSearchable={false}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  )
}

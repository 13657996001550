import React, { useState, useEffect } from "react";
import "./home.css";
import { tabName } from "../../utils/constants";
import WarrantTool from "./tabs/WarrantTool/warrantTool";
import WarrantEducation from "./tabs/warrantEduction";
import WarrantStatistics from "./tabs/warrantStatistics";
import MarketNews from "./tabs/marketNews";
import SubscribeModel from "../../components/common/subscribeModal";
import { Link } from "react-router-dom";
import { get } from "lodash";
import API from "../../utils/apiCalling";
import { config } from "../../utils/apiUrl";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = ({ Location, Hist }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const api = new API();
  const [activeTab, setActiveTab] = useState("Warrant tools");
  const [open, setOpen] = useState(false);
  const [count, setcount] = useState(11);
  const [popupData, setPopupData] = useState([]);
  const [popupDataMobile, setPopupDataMobile] = useState([]);
  const [popupData11, setPopupData11] = useState("");
  const [popup11, setPopup11] = useState(false);
  const [homePopupPhoto, setHomePopupPhoto] = useState("");

  useEffect(() => {
    var urlSearchData = window.location.search;
    if (urlSearchData == "?dwstat") {
      setActiveTab("Warrant statistics");
    } else if (urlSearchData == "?edu") {
      setActiveTab("Warrant education");
    } else if (urlSearchData == "?news") {
      setActiveTab("Market news");
    } else {
      setActiveTab("Warrant tools");
    }
  }, []);

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    //vertical: true,
  };
  const settingss = {
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    //vertical: true,
  };

  useEffect(() => {
    getHomePopup();
    getHomePopupMobile();
    getHomePopupPhoto();
  }, []);

  const getHomePopup = async () => {
    let homePopupResult = await api.get(config.homePopup, true);
    setPopupData11(homePopupResult.popupdata);
    if (homePopupResult?.popupdata[0].status == "0") {
      setPopup11(false);
    } else {
      getpopupCookie();
    }
    let homeMainBanner = homePopupResult.homedata;
    let items = [];
    if (homeMainBanner?.length > 0) {
      for (let i = 0; i < homeMainBanner.length; i += 2) {
        let advImgData = await api.get(config.advImgData, true, {
          home: homeMainBanner[i].id,
        });
        items.push({
          imgSrc: advImgData,
          link: homeMainBanner[i].link,
        });
      }
      setPopupData(items);
    }
  };
  const getHomePopupPhoto = async () => {
    let homePopupResult = await api.get(config.homePhoto + "?popup=0", true);
    setHomePopupPhoto(homePopupResult);
  };

  const getpopupCookie = () => {
    var x = JSON.parse(localStorage.getItem("lightbox0001"));
    if (x == null) {
      setPopup11(true);
      updatePopuptime();
    } else {
      if ((Date.parse(new Date()) - x.time) / (24 * 60 * 60 * 1000) > 365) {
        setPopup11(true);
        updatePopuptime();
        localStorage.removeItem("lightbox0001");
      } else {
        setPopup11(false);
      }
    }
  };

  const setpopupCookie = () => {
    setPopup11(false);
    var value = {
      status: "0",
      time: Date.parse(new Date()),
      // time:Date.parse(new Date('2022/8/25'))
    };
    localStorage.setItem("lightbox0001", JSON.stringify(value));
  };

  const getHomePopupMobile = async () => {
    let homePopupResult = await api.get(config.homePopup, true);
    if (homePopupResult?.popupdata[0].status == "0") {
      setPopup11(false);
    } else {
      getpopupCookie();
    }
    let homeMainBanner = homePopupResult.homedata;
    let items = [];
    if (homeMainBanner.length > 0) {
      for (let i = 1; i < homeMainBanner.length; i += 2) {
        let advImgData = await api.get(config.advImgData, true, {
          home: homeMainBanner[i].id,
        });
        items.push({
          imgSrc: advImgData,
          link: homeMainBanner[i].link,
        });
      }
      setPopupDataMobile(items);
    }
  };

  const isActive = (tab) => {
    if (activeTab === tab) {
      return "active";
    }
    return "";
  };

  const updatePopuptime = () => {
    try {
      var c = 11;
      var ninterval = setInterval(() => {
        setcount(c);
        c--;
        if (c === 0) {
          clearInterval(ninterval);
          setPopup11(false);
        }
      }, 1000);
    } catch (error) {}
  };

  const renderTab = () => {
    switch (activeTab) {
      case tabName.warrantTool:
        return <WarrantTool open={open} setOpen={setOpen} />;
        break;
      case tabName.warrantEducation:
        return <WarrantEducation />;
        break;
      case tabName.warrantStatistics:
        return <WarrantStatistics />;
        break;
      case tabName.marketNews:
        return <MarketNews />;
        break;
      default:
        return null;
        break;
    }
  };

  return (
    <>
      <div id="home">
        <div className="hometop" style={{ zIndex: 0 }}>
          <Slider {...settings} className="show-desk">
            {popupData &&
              popupData.map((data, index) => {
                return (
                  <div className="gallery-item" key={index}>
                    <a
                      className="homeimg-achor home_banner1"
                      target="_blank"
                      href={data.link}
                    >
                      <img
                        className="hiddn_mob"
                        style={{ width: "100%", marginTop: 70 }}
                        src={data.imgSrc}
                      />
                    </a>
                  </div>
                );
              })}
          </Slider>

          <Slider {...settingss} className="show-mob">
            {popupDataMobile &&
              popupDataMobile.map((data, index) => {
                return (
                  <div className="gallery-item" key={index}>
                    <a
                      className="homeimg-achor home_banner1"
                      target="_blank"
                      href={data.link}
                    >
                      <img
                        className="hiddn_mob"
                        style={{ width: "100%", marginTop: 70 }}
                        src={data.imgSrc}
                      />
                    </a>
                  </div>
                );
              })}
          </Slider>
        </div>
        <div className="jumbotron" style={{ display: "none" }}>
          <div className="container-fluid">
            <h1 style={{ color: "#FECC0A", fontWeight: 600 }}>
              Structured Warrants
            </h1>
            <p className="lead">Take your first step now</p>
            <a
              className="btn btn-lg btn-primary text-bold"
              href="http://bit.ly/2R7Qude"
              role="button"
            >
              Learn more
            </a>
          </div>
        </div>
        <nav className="tabnav container-fluid">
          <div className="row">
            <div className="col-md-3">
              <div
                onClick={() => setActiveTab(tabName.warrantTool)}
                className={`tabnav-item tabnav-item-dwtool ${isActive(
                  tabName.warrantTool
                )}`}
                data-value="dwtool"
              >
                <div className="tabnav-img" />
                <h2 className="tabnav-htxt">Warrant tools</h2>
                <div className="tabnav-txt sm-none xs-none">
                  Stay ahead of the market with our warrant tools.
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                onClick={() => setActiveTab(tabName.warrantStatistics)}
                className={`tabnav-item tabnav-item-dwstat ${isActive(
                  tabName.warrantStatistics
                )}`}
                data-value="dwstat"
              >
                <div className="tabnav-img" />
                <h2 className="tabnav-htxt">Warrant statistics</h2>
                <div className="tabnav-txt sm-none xs-none">
                  Our customised graphs and charts provide excellent insight
                  into the markets.
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                onClick={() => setActiveTab(tabName.warrantEducation)}
                className={`tabnav-item tabnav-item-dwedu ${isActive(
                  tabName.warrantEducation
                )}`}
                data-value="dwedu"
              >
                <div className="tabnav-img" />
                <h2 className="tabnav-htxt">Warrant education</h2>
                <div className="tabnav-txt sm-none xs-none">
                  New to warrants? We will help you get started and grow your
                  knowledge.
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                onClick={() => setActiveTab(tabName.marketNews)}
                className={`tabnav-item tabnav-item-dwnews ${isActive(
                  tabName.marketNews
                )}`}
                data-value="dwnews"
              >
                <div className="tabnav-img" />
                <h2 className="tabnav-htxt">Market news</h2>
                <div className="tabnav-txt sm-none xs-none">
                  Market news as it breaks. Stay informed and ready.
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div className="tab-container container-fluid">{renderTab()}</div>
        <SubscribeModel Open={open} HandleModel={() => setOpen(!true)} />
        <div className="banner">
          <div className="banner-wapper container-fluid">
            <Link to="/education/whymacquarie">
              <img className="banner-img" src="/img/home/Why_Macquarie_updated.jpg" />
            </Link>
          </div>
        </div>
      </div>
      {popup11 && (
        <div id="lightbox">
          <div>
            <div className="inner-area">
              <a
                className="countdown-a"
                // className="btn-close"
                onClick={() => setPopup11(false)}
                style={{ color: "#" + popupData11[0]?.close_color }}
              >
                X
              </a>
              <div
                className="countdown-txt"
                style={{ color: "#" + popupData11[0]?.timer_color }}
              >
                Closes after <span id="ctlbl">{count}</span> seconds...
              </div>

              <Link
                id="lightbox_link"
                to={{ pathname: `${popupData11[0]?.link}` }}
              >
                <img src={homePopupPhoto} />
              </Link>
              <a
                onClick={setpopupCookie}
                style={{ color: "#" + popupData11[0]?.dont_show_color }}
                className="noshow text-bold "
              >
                Don't show again
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Home;

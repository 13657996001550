import React, { useState, useEffect } from 'react';
import "./activitie.css";
import { Link } from 'react-router-dom';



const Activities = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div id="activities" className="pageobj" style={{}}>
            <div className="breadcrumbbar">
                <div className="container-fluid">
                <ol className="breadcrumb">
                    <li>
                    <Link to="/home?news">Market news</Link>
                    </li>
                    <li className="active">Activities</li>
                </ol>
                </div>
            </div>
            <div className="stripe-bg" />
            <div className="container-fluid">
                <div className="row">
                <div className="col-md-12">
                    <div className="section">
                    <div className="page-header">
                        <h1>Activities</h1>
                    </div>
                    <p>The latest events and activities from Macquarie.</p>
                    <div className="photo-main">
                        <img
                        src="/img/activities/warrant_warrior_2017.jpg"
                        alt="warrant warrior 2017"
                        />
                    </div>
                    <div className="photo-tip">
                        <h2>Warrant Warrior 2017</h2>
                        <Link 
                        target="_blank"
                        to="/marketnews/activities/warrior/2017"
                        >
                        <div className="view_btn">View more</div>
                        </Link>
                    </div>
                    <h3>More Activities</h3>
                    <div className="row">
                        <div className="col-sm-4">
                        <div className="photo-sub">
                            <img src="/img/activities/warrant_hero_2015_nail.jpg" />
                        </div>
                        <div className="photo-tip photo_tip1">
                            <div className="tip-text">Warrant Hero 2015</div>
                            <Link 
                        target="_blank"
                        to="/marketnews/activities/hero/2015"
                        >
                            <div className="view_btn">View more</div>
                            </Link>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
      );
}
export default Activities;
import React, { useState, useEffect,Fragment } from 'react';
import TechnicalEvent from './TechnicalEvent';
import "./technicalevent.css" 


const WarrantTechnicalEvent = (props) => {
    return ( 
        <Fragment>
        <TechnicalEvent/>
        </Fragment> 
     );
}


export default WarrantTechnicalEvent;
import React, { useState, useEffect,Fragment } from 'react';
import PhotoGalleries from './PhotoGalleries';
import "./photoGallery.css" 


const PhotoGalleryPage = (props) => {
    return ( 
        <Fragment> 
        <PhotoGalleries/>
        </Fragment> 
     );
}


export default PhotoGalleryPage;
import React, { useState, useEffect } from 'react';
import "./photoGallery.css";
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash';
import { Link } from 'react-router-dom';



const PhotoGalleries = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const api = new API()

    const[load,setLoad] = useState(false)
    const [photoGallery, setPhotoGallery] = useState([])
    
    useEffect(() => {
      getphotoGalleryResult()
    },[])
    
    const getphotoGalleryResult = async () => { 
        let photoGalleryResult = await api.get(config.photoGallery, true,{qid:Date.now()} )
        let items = [];
        if(photoGalleryResult.length > 0){
            for (let i = 0; i < photoGalleryResult.length; i++) {
                let advImgData = await api.get(config.advImgData, true, {album_id:photoGalleryResult[i].id});
                items.push({
                    imgSrc: advImgData,
                    id: photoGalleryResult[i].id,
                    en_title: photoGalleryResult[i].en_title
                })
            }
            setPhotoGallery(items)
        }
        setLoad(true)
    }

    return (
        <div id="photogallery" className="pageobj" style={{}}>
            <div className="breadcrumbbar">
                <div className="container-fluid">
                <ol className="breadcrumb">
                    <li>
                    <a href="/home?news">Market news</a>
                    </li>
                    <li className="active">Photo gallery </li>
                </ol>
                </div>
            </div>
            <div className="stripe-bg" />
            <div className="container-fluid">
                <div className="section">
                <div className="page-header">
                    <h1>Photo gallery</h1>
                </div>
                <p>
                    Visit our photo gallery to get a closer look at all our recent events.
                </p>
                <div id="albums" style={{ position: "relative" }}>
                    {(load && photoGallery.length === 0)  &&  "No photo gallery album"}
                    {load && photoGallery.map((d, index)=>
                      <div className="albumtmpl" key={index}>
                          <div className="bg-layer">
                              <img src={d.imgSrc} />
                          </div>
                          <div className="content-layer">
                              <table>
                                  <tbody><tr>
                                      <td>
                                          <Link target="_blank" to={`/marketnews/individualgallery?album_id=${d.id}`} >
                                              <h2>{d.en_title}</h2>
                                              <div className="view_btn">View more</div>
                                          </Link>
                                      </td>
                                  </tr>
                              </tbody></table>
                          </div>
                      </div>
                    )}
                </div>
                <hr style={{ marginTop: "20px" }} className="hr-hmgbm" />
                
                </div>
            </div>
        </div>
      );
}
export default PhotoGalleries;



import React from 'react';
const Disclaimer = () => {
  return (
    <div id="disclaimer" className="disclaimer-overlay" style={{display: 'none'}}>
    <div className="disclaimer-overlay-inner">
      <div style={{padding: '10px'}}>
        <div className="disclaimer-page-header" style={{textAlign: 'left'}}>
          <h1 className="small">Terms of use and privacy policy</h1>
        </div>
        <p style={{textAlign: 'left'}}>
          To continue, you must agree to our terms of use and privacy policy.
        </p>
        <div className="popupbox-content">
          <span className="bold disclaimer-title">Terms of use</span>
          <br /><br /> This internet site is produced by Macquarie Capital Securities (Malaysia) Sdn. Bhd. (“MCSM”).<br /><br /> The information on this internet site is directed and available for general circulation to residents of Malaysia only and
          is not provided to any person who is a resident of the United States or any other country. Any material provided herein, including any indicative terms are provided for information purposes only and do not constitute an offer, a solicitation
          of an offer, or any advice or recommendation to conclude any transaction. The Warrants will not be offered to any US persons.<br /><br /> The price of securities may go down as well as up, and under certain circumstances an investor may
          sustain a total loss of investment. As past performance is not an indication of future performance, an investor should ensure that they understand the nature of the investment product and in particular the risk factors before investing.<br />
          <br />
          <span className="bold">Macquarie and the Material on this website</span><br /><br /> Macquarie Malaysia warrants ("Warrants") are issued by MCSM and guaranteed by Macquarie Financial Holdings Limited. Except where expressly stated, the information
          and services on this website (the "Material") are provided by MCSM as at the date indicated on the relevant material.<br /><br /> MCSM is not an authorised deposit-taking institution for the purposes of the Banking Act (Cth) 1959, and
          MCSM's obligations do not represent deposits or other liabilities of Macquarie Bank Limited (ABN 46 008 583 542) ("MBL"). MBL does not guarantee or otherwise provide assurance in respect of the obligations of MCSM.<br /><br /> In relation
          to Warrants which are listed on Bursa Malaysia Securities Berhad ("Bursa Securities"), a base prospectus has been prepared. In addition a termsheet will or has been prepared by MCSM for the purpose of obtaining a listing for a series
          of Warrants on Bursa Securities. Copies of the base prospectus and termsheet (together the “Listing Documents”) may be obtained on this web-site.<br /><br /> To the maximum extent permitted by law, none of MCSM or its affiliates or the
          directors, officers employees or agents, of those entities gives any representation or warranty as to the reliability, completeness, timeliness, accuracy or fitness for any purpose of the Material, or any third party website linked
          to this website, nor does any of them accept any responsibility or liability for any loss or damage arising in any way (including by reason of negligence) out of errors or misstatements in, omissions from, or the reliance of any person
          on, the Material.<br />
          <br />
          <span className="bold">Third Party Information</span><br /><br /> The Material in this site has been prepared in good faith with reasonable care. However, certain parts of this Material is obtained or is based upon information obtained from
          third parties which may not have been checked or verified. Except to the extent permitted by law, and only to the extent so required, no member of the Macquarie group makes any warranty in relation to, or accepts any responsibility
          or liability for any loss or damage suffered by any person arising out of or in relation to the Material. The Material on this site is not for circulation or publication without the prior written consent of MCSM. MCSM may not, and
          has no obligation to, update the Material or correct any inaccuracy which subsequently becomes apparent. Opinions, estimates and other information in the Material may be changed or withdrawn without notice.<br />
          <br />
          <span className="bold">Opinions, ideas and strategies</span><br /><br /> The opinions, ideas and strategies in this document are based on certain assumptions and current market conditions. If those underlying assumptions or market conditions
          change, the opinions, ideas and strategies will change. All quotations are indicative only. A quotation can be provided by contacting MCSM directly. The strategies outlined are not intended to be specific and/or personal advice and
          MCSM expressly disclaims all or any liability and responsibility to any person in respect to anything (and the consequences of anything) done or omitted to be done by any person in reliance on the whole or any part of this publication.<br />
          <br />
          <span className="bold">No personal investment advice – seek personalised advice from a qualified adviser before investing.</span><br /><br /> Any general advice contained on this website has been prepared by MCSM and does not take account
          of your objectives, financial situation or needs. Before acting on this general advice, you should consider the appropriateness of the advice having regard to your situation. We recommend you obtain financial, legal and taxation advice
          before making any financial investment decision. Macquarie or its associates, officers or employees may have interests in the financial products referred to in this information by acting in various roles including as investment banker,
          underwriter or dealer, holder of principal positions, broker, lender or adviser. Macquarie or its associates may receive fees, brokerage or commissions for acting in those capacities. In addition, Macquarie or its associates, officers
          or employees may buy or sell the financial products as principal or agent and as such may effect transactions which are not consistent with any recommendations in the information. All investors should obtain the Listing Documents for
          the products discussed and should consider it in full before making a decision.<br />
          <br />
          <span className="bold disclaimer-title">Privacy policy</span><br /><br /> Macquarie Capital Securities (Malaysia) SDN BHD<br /><br /> Macquarie Equipment Leasing SDN BHD<br /><br />
          <a href="http://www.macquarie.com/my/about/disclosures/malaysia-privacy-policy-my" target="_blank">View Malay version</a><br />
          <br />
          <span className="bold">Personal data notice relating to the Personal Data Protection Act 2010 (The "Act")</span><br /><br /> This personal data notice applies to any person whose personal data is processed by or on behalf of Macquarie Capital
          Securities (Malaysia) Sdn Bhd or Macquarie Equipment Leasing Sdn. Bhd. (“we” or “us”). Terms not defined in this notice shall have the meaning given to them in the Act (if any).<br /><br /> If you provide or you have provided to us any
          personal data of another individual including your directors, officers and/or employees, you (i) represent and warrant that you have obtained sufficient informed consent from each of such individuals to the processing of his/her personal
          data by us, (ii) will provide us with evidence of such consent when requested by us, and (iii) shall provide a copy of this notice to each of such individuals. References to “you” in this notice shall include all relevant data subjects.<br />
          <br /> Please note that this notice may be amended from time to time and the data subject shall visit <a href="https://www.macquarie.com/au/en/disclosures/privacy-and-cookies.html" target="_blank">https://www.macquarie.com/au/en/disclosures/privacy-and-cookies.html</a>                    on a regular basis for updates and changes.<br />
          <br />
          <span className="bold">The personal data we process</span><br /><br /> The personal data processed by us may include one or more of the following: your name, address, proof of identification (eg. National Registration Identity Card, driver’s
          license and passport), contact details, email address, occupation, sample of signature, information of any past breaches of or convictions under any law, your financial position, results from third party searches, the relevant products
          or services provided to you and any other personal data required for the purposes set out in paragraph 3 below (collectively “your personal data”).<br /><br /> Sensitive personal data may include such things as information on physical
          or mental health or medical condition, political opinions, religious or other similar beliefs, commission or alleged commission of any offence.<br /><br /> Although it is not obligatory to provide your personal data to us, failure to supply
          such personal data may result in us being unable to open or continue accounts or provide brokerage or financial services.<br />
          <br />
          <span className="bold">Purposes</span><br /><br /> We may process your personal data for the following purposes:
          <ul>
            <li>to fulfil our legal or regulatory obligations under applicable laws and rules, such as those relating to anti-money laundering and counter-terrorism financing (including identification verification);</li>
            <li>to consider applications for products and/or services (including assessing and/or verifying your creditworthiness);</li>
            <li>to communicate with you including responding to your enquiries;</li>
            <li>to provide you with information on products, services and/or events offered/organised by us, our affiliates or our business partners;</li>
            <li>to perform control and risk management functions;</li>
            <li>to enforce our rights; and</li>
            <li>any other purpose that is incidental or ancillary to the above purposes.</li>
          </ul>
          <br />
          <span className="bold">Sources</span><br /><br /> Your personal data is collected from various sources, including information you have provided to us, information from third parties and information in the public domain.<br />
          <br />
          <span className="bold">Access and request for correction</span><br /><br /> You may contact us by writing to us if you wish to request for access to your personal data that we retain about you, request for your personal data retained by us
          to be corrected, or withdraw your consent to the processing or use of your personal data by us. We may charge a reasonable fee for processing your request. We may also refuse to comply with your request in accordance with the Act.
          Any request, inquiries or complaints can be sent to privacy@macquarie.com by email or attention to the Complaint Officer at Level 10, Menara Dion, 27 Jalan Sultan Ismail, 50250 Kuala Lumpur, Malaysia (Tel: +60 3 2059 8833).<br />
          <br />
          <span className="bold">Disclosure of personal data</span><br /><br /> We may share your personal data with entities within the Macquarie Group of companies including all related companies, subsidiaries, holding companies and associated companies,
          and may disclose your personal data (whether in or outside Malaysia):
          <ul>
            <li>as required by applicable laws or rules, such as those relating to anti-money laundering and counter-terrorism financing, or as required by regulatory, supervisory, law enforcement or governmental bodies;</li>
            <li>to any court of competent jurisdiction in defense of claims or enforcement of rights;</li>
            <li>to service providers who provide services in connection with our products and services;</li>
            <li>to our professional advisors on a need-to-know basis; or</li>
            <li>any other persons which we reasonably think fit.</li>
          </ul>
          <br />
          <span className="bold">Transfer of information</span><br /><br /> Your personal data may be transferred to a place outside of Malaysia.<br /><br /> In the event of any inconsistency or conflict between the English language version and the Bahasa
          Malaysia version of this Personal Data Notice, the English language version shall prevail.<br /><br /> By providing to us your personal data and/or should you continue to trade with us (where applicable), you hereby consent to the collection,
          processing, use, disclosure and retention of your personal data in accordance with all of the foregoing.<br />
          <br />
          <span className="bold">Macquarie Capital Securities (Malaysia) Sdn Bhd</span><br /><br />
          <span className="bold">Macquarie Equipment Leasing Sdn Bhd</span><br /><br /> February 2014
        </div>
        <div className="disclaimer_btn" style={{textAlign: 'right'}}>
          <div className="btn btn-primary back-btn" style={{color: '#fff !important', backgroundColor: '#b3b3b3 !important', borderColor: '#b3b3b3 !important'}}>Back</div>
          <div className="btn btn-primary close-btn">Accept</div>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default Disclaimer;

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import QuickTerms from "./QuickTerms";
import { useReactToPrint } from "react-to-print";
import ReactTooltip from "react-tooltip";
import SelectComp from "./SelectComp";
import { Spinner } from "reactstrap";

function LiveMatrixCard({
  page,
  setRic,
  setLocalRic,
  symbol,
  resultName,
  activeTab,
  load,
  result,
  lastUpdated,
  localRic,
  index,
  setCardNo,
  manageUrl,
  hotList,
  cdata,
  loading,
  cardNo,
  inputValWarr,
  recogniaData,
  dataSymbol,
  contractData,
  sp500Contract,
  futureName,
  clearName,
  hashArray
}) {
  const myRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => myRef.current,
  });

  const getRic = (e) => {
    setRic(e.split(",")[0]);
    setCardNo(index);
    manageUrl(e.split(",")[0], index);
  };
  const updateRic = (d) => {
    const s = d.ric.concat(",").concat(d.ticker);
    getRic(s);
  };
  return (
    <>
      <div
        id="lm0"
        className="lm-table col-xs-12 xs-none sm-none"
        style={{ position: "relative", width: page > 1 && "33.33%" }}
      >
        <div id="top_area">
          <div id="dropdown-livematrix">
            {symbol.length>0 && (
              <div style={{ textAlignLast: "center" }}>
                <SelectComp
                  data={symbol}
                  placeholder="Select Warrant"
                  getUnderlyingData={getRic}
                  status="true"
                  inputValWarr={inputValWarr}
                  clearName={clearName}
                  hashArray={hashArray}
                />
              </div>
            )}
          </div>
          {resultName[index] ? (
            <div className="dataview">
              <div className="ricbar">
                <div
                  id="ticker"
                  className="ricbar-lbl"
                  style={{ textAlign: "center" }}
                >
                  {resultName[index] &&
                    `${resultName[index]?.dsply_nmll} (${resultName[index]?.ticker})`}
                </div>
              </div>
              <div className="btnbar">
                <div className="img-icon-sec">
                  {resultName[index] && resultName[index]?.APPCHEXPRY && (
                    <>
                      <img
                        src="/img/bullet/expiry.fw.png"
                        className="ttip"
                        data-tip
                        data-for={"tooltip_1"}
                      />
                      <ReactTooltip
                        id={"tooltip_1"}
                        delayHide={200}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Near expiry:</strong> Indicates SWs with
                            less than 1 month to expiry. These warrants have
                            higher holding costs due to a higher rate of time
                            decay, so please exercise caution when trading these
                            warrants.
                          </p>
                        </div>
                      </ReactTooltip>
                    </>
                  )}
                  {resultName[index] && resultName[index]?.HIGHLQDY && (
                    <>
                      <img
                        src="/img/bullet/liquidity.fw.png"
                        className="ttip"
                        data-tip
                        data-for={"tooltip_2"}
                      />
                      <ReactTooltip
                        id={"tooltip_2"}
                        delayHide={200}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Highest liquidity:</strong> Highlights the
                            Macquarie SW in each underlying with the largest
                            volume on the current bid and offer, indicating the
                            highest liquidity. This is often also the most
                            actively traded SW.
                          </p>
                        </div>
                      </ReactTooltip>
                    </>
                  )}
                  {resultName[index] && resultName[index]?.MORERESPONSIVE && (
                    <>
                      <img
                        src="/img/bullet/responsive.png"
                        className="ttip"
                        data-tip
                        data-for={"tooltip_3"}
                      />
                      <ReactTooltip
                        id={"tooltip_3"}
                        delayHide={200}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>More responsive:</strong>SWs with this
                            indicator are more responsive to price movements in
                            the underlying.
                          </p>
                        </div>
                      </ReactTooltip>
                    </>
                  )}
                  {resultName[index] &&
                    resultName[index]?.HIGHEFFECTIVEGEARING && (
                      <>
                        <img
                          src="/img/bullet/effGearing.png"
                          className="ttip"
                          data-tip
                          data-for={"tooltip_4"}
                        />
                        <ReactTooltip
                          id={"tooltip_4"}
                          delayHide={200}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>High effective gearing:</strong> SWs with
                              this indicator have relatively higher effective
                              gearing level, meaning the SW price will move at a
                              larger percentage relative to a price movement in
                              the underlying, translating to a higher profit
                              potential but also higher level of risk.
                            </p>
                          </div>
                        </ReactTooltip>
                      </>
                    )}
                  {resultName[index] && resultName[index]?.LOWTIMEDECAY && (
                    <>
                      <img
                        src="/img/bullet/timeDecay.png"
                        className="ttip"
                        data-tip
                        data-for={"tooltip_5"}
                      />
                      <ReactTooltip
                        id={"tooltip_5"}
                        delayHide={200}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Low time decay:</strong> SWs with this
                            indicator have relatively lower time decay, meaning
                            lower holding costs for investors. Generally, all
                            else constant, longer dated SWs have lower time
                            decay than shorter dated SWs and vice versa.
                          </p>
                        </div>
                      </ReactTooltip>
                    </>
                  )}
                  {resultName[index] && resultName[index]?.NEWWARRANT && (
                    <>
                      <img
                        src="/img/bullet/new.png"
                        className="ttip"
                        data-tip
                        data-for={"tooltip_6"}
                      />
                      <ReactTooltip
                        id={"tooltip_6"}
                        delayHide={200}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>New warrant:</strong> SWs with this
                            indicator are relatively new, i.e. listed in the
                            last week.
                          </p>
                        </div>
                      </ReactTooltip>
                    </>
                  )}
                  {resultName[index] && resultName[index]?.TRENDINGWARRANTS && (
                    <>
                      <img
                        src="/img/bullet/hot.fw.png.png"
                        className="ttip"
                        data-tip
                        data-for={"tooltip_7"}
                      />
                      <ReactTooltip
                        id={"tooltip_7"}
                        delayHide={200}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Trending warrants:</strong> The Trending
                            warrants are selected by Macquarie as the daily
                            highlights for investors. The market maker will aim
                            to provide high liquidity for these warrants and, if
                            possible, will try (but is not obliged) to sell to
                            investors queuing at the market maker’s best bid
                            price.
                          </p>
                        </div>
                      </ReactTooltip>
                    </>
                  )}
                  {resultName[index] && resultName[index]?.SOLDOUT && (
                    <>
                      <img
                        src="/img/bullet/soldout.fw.png"
                        className="ttip"
                        data-tip
                        data-for={"tooltip_8"}
                      />
                      <ReactTooltip
                        id={"tooltip_8"}
                        delayHide={200}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Inventory sold-out:</strong> SWs with this
                            indicator are either sold out or close to being sold
                            out, meaning the market maker may no longer provide
                            an offer price or have widened spreads by raising
                            the offer price. Existing holders are not
                            disadvantaged as the bid price is still maintained
                            at its fair price. However, please exercise caution
                            if you are looking to buy this SW as the market
                            price may be inflated by other investors queuing in
                            the market. Do check the Live Matrix for the
                            warrant’s fair bid price.
                          </p>
                        </div>
                      </ReactTooltip>
                    </>
                  )}
                </div>
                <div className="btnbar2">
                  <div className="viewTechnicalbtn">
                    <Link
                      to={`/tools/recognia?sym=${
                        recogniaData && recogniaData.reco_symbol
                      }&exch=${
                        recogniaData && recogniaData.reco_exchange
                      }&ticker=${recogniaData && recogniaData.ticker}&ric=${
                        recogniaData && recogniaData.ric
                      }`}
                      id="viewTechnical"
                      type="button"
                      target="_blank"
                      className="technicalbtn btn btn-block btn-primary"
                    >
                      Technical chart
                    </Link>
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    <button
                      type="button"
                      className="btn btn-block btn-primary printbtn"
                      onClick={() => handlePrint()}
                    >
                      <img
                        className="printimg"
                        src="/img/bullet/print.png"
                        alt="print live matrix"
                      />
                    </button>
                  </div>
                </div>
              </div>

              {/* {
                            resultName && resultName[index] && resultName[index].dsply_nmll && resultName[index].dsply_nmll.includes("SP")
                                ? <p id="p-contract" className="p-contract" style={{textAlign: "center"}}>Underlying price refers to the relevant <b>S&P 500<sup>®</sup>  {resultName[index].future_dsply_name.split(' ')[resultName[index].future_dsply_name.split(' ').length-1]}</b> futures contract</p>
                                : resultName && resultName[index] && resultName[index].dsply_nmll && resultName[index].dsply_nmll.includes("DJIA")
                                ? <p id="p-contract" className="p-contract" style={{textAlign: "center"}}>Underlying price refers to <b>DJIA<sup>®</sup>  {resultName[index].future_dsply_name.split(' ')[resultName[index].future_dsply_name.split(' ').length-1]}</b> futures contract</p>
                                : resultName && resultName[index] && resultName[index].dsply_nmll && resultName[index].dsply_nmll.includes("NDX")
                                ? <p id="p-contract" className="p-contract" style={{textAlign: "center"}}>Underlying price refers to <b>NASDAQ-100<sup>®</sup>  {resultName[index].future_dsply_name.split(' ')[resultName[index].future_dsply_name.split(' ').length-1]}</b> futures contract</p>
                                : resultName && resultName[index] && resultName[index].underlying_name && resultName[index].underlying_name.includes("N225")
                                ?<p id="p-contract" className="p-contract" style={{textAlign: "center",display:resultName[index].future_dsply_name && resultName[index].future_dsply_name!='-'?'block':'none'}}>Underlying price refers to <b>{resultName[index].future_dsply_name}</b> futures contract</p>
                                :<p id="p-contract" className="p-contract" style={{textAlign: "center",display:resultName[index].future_dsply_name && resultName[index].future_dsply_name!='-'?'block':'none'}}>Underlying price refers to <b>{resultName[index].future_dsply_name}</b> futures contract</p>
                                
                            } */}
              {resultName &&
              resultName[index] &&
              resultName[index].dsply_nmll &&
              resultName[index].dsply_nmll.includes("SP500") ? (
                <p
                  id="p-contract"
                  className="p-contract"
                  style={{ textAlign: "center" }}
                >
                  Underlying price refers to the relevant{" "}
                  <b>
                    S&P 500<sup>®</sup>{" "}
                    {
                      resultName[index].future_dsply_name.split(" ")[
                        resultName[index].future_dsply_name.split(" ").length -
                          1
                      ]
                    }
                  </b>{" "}
                  futures contract
                </p>
              ) : resultName &&
                resultName[index] &&
                resultName[index].dsply_nmll &&
                resultName[index].dsply_nmll.includes("DJIA") ? (
                <p
                  id="p-contract"
                  className="p-contract"
                  style={{ textAlign: "center" }}
                >
                  Underlying price refers to{" "}
                  <b>
                    DJIA<sup>®</sup>{" "}
                    {
                      resultName[index].future_dsply_name.split(" ")[
                        resultName[index].future_dsply_name.split(" ").length -
                          1
                      ]
                    }
                  </b>{" "}
                  futures contract
                </p>
              ) : resultName &&
                resultName[index] &&
                resultName[index].dsply_nmll &&
                resultName[index].dsply_nmll.includes("NDX") ? (
                <p
                  id="p-contract"
                  className="p-contract"
                  style={{ textAlign: "center" }}
                >
                  Underlying price refers to{" "}
                  <b>
                    NASDAQ-100<sup>®</sup>{" "}
                    {
                      resultName[index].future_dsply_name.split(" ")[
                        resultName[index].future_dsply_name.split(" ").length -
                          1
                      ]
                    }
                  </b>{" "}
                  futures contract
                </p>
              ) : resultName &&
                resultName[index] &&
                resultName[index].underlying_name &&
                resultName[index].underlying_name.includes("NIKKEI") ? (
                <p
                  id="p-contract"
                  className="p-contract"
                  style={{
                    textAlign: "center",
                    display:
                      resultName[index].future_dsply_name &&
                      resultName[index].future_dsply_name != "-"
                        ? "block"
                        : "none",
                  }}
                >
                  Underlying price refers to{" "}
                  <b>{resultName[index].future_dsply_name}</b> futures contract
                </p>
              ) : (
                <p
                  id="p-contract"
                  className="p-contract"
                  style={{
                    textAlign: "center",
                    display:
                      resultName[index].future_dsply_name &&
                      resultName[index].future_dsply_name != "-"
                        ? "block"
                        : "none",
                  }}
                >
                  Underlying price refers to{" "}
                  <b>{resultName[index].future_dsply_name}</b> futures contract
                </p>
              )}

              {activeTab === "liveMatrixPlus" && (
                <div className="lmview" ref={myRef}>
                  <div id="lmtablecontainer">
                    {result[index] === null ||
                    result[index] === undefined ||
                    (result[index]?.length === 0 &&
                      loading &&
                      load &&
                      cardNo === index) ? (
                      <div className="spinner-div">
                        <Spinner className="spinner-card" />
                      </div>
                    ) : (
                      ""
                    )}

                    <table id="today_table" className="table lbtable">
                      <thead>
                        <tr>
                          <th width="30%">
                            <span className="th-left">Underlying bid</span>
                          </th>
                          <th width="20%">
                            <span className="th-left">Warrant bid</span>
                          </th>
                          <th width="20%">
                            <span className="th-right">Warrant offer</span>
                          </th>
                          <th width="20%">
                            <span className="th-right">Underlying offer</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {load && result[index]?.length === 0 && (
                          <tr>
                            <td colSpan={6} className="text-content">
                              The matrix for this warrant is currently
                              unavailable. Please call us at +603-2059 8840 for
                              further details.
                            </td>
                            {/* <td colSpan={6} className="text-content">
                                                        There is no matrix available for this
                                                        warrant, as the warrant has no value at
                                                        current levels in the underlying
                                                    </td> */}
                          </tr>
                        )}

                        {result[index] &&
                          result[index].map((d, firindex) => (
                            <tr
                              className={`${d.classList}`}
                              key={"10" + firindex}
                            >
                              <td>{Number(d.underlying_bid).toFixed(3)}</td>
                              <td>{Number(d.bid).toFixed(3)}</td>
                              <td>{Number(d.ask).toFixed(3)}</td>
                              <td>{Number(d.underlying_ask).toFixed(3)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div id="last_update">
                    Last update:<span>{lastUpdated}</span>
                  </div>
                </div>
              )}

              {/* Just for Printing */}
              {activeTab !== "liveMatrixPlus" && (
                <div
                  className="lmview"
                  ref={myRef}
                  style={{ position: "absolute", zIndex: -1000 }}
                >
                  <div id="lmtablecontainer">
                    <table id="today_table" className="table lbtable">
                      <thead>
                        <tr>
                          <th width="30%">
                            <span className="th-left">Underlying bid</span>
                          </th>
                          <th width="20%">
                            <span className="th-left">Warrant bid</span>
                          </th>
                          <th width="20%">
                            <span className="th-right">Warrant offer</span>
                          </th>
                          <th width="20%">
                            <span className="th-right">Underlying offer</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {load && result[index]?.length === 0 && (
                          <tr>
                            {/* <td colSpan={6} className="text-content">
                                                        There is no matrix available for this
                                                        warrant, as the warrant has no value at
                                                        current levels in the underlying
                                                    </td> */}
                            <td colSpan={6} className="text-content">
                              The matrix for this warrant is currently
                              unavailable. Please call us at +603-2059 8840 for
                              further details.
                            </td>
                          </tr>
                        )}

                        {result[index] &&
                          result[index].map((d, secindex) => (
                            <tr
                              className={`${d.classList}`}
                              key={"11" + secindex}
                            >
                              <td>{Number(d.underlying_bid).toFixed(3)}</td>
                              <td>{Number(d.bid).toFixed(3)}</td>
                              <td>{Number(d.ask).toFixed(3)}</td>
                              <td>{Number(d.underlying_ask).toFixed(3)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div id="last_update">
                    Last update:<span>{lastUpdated}</span>
                  </div>
                </div>
              )}
              {activeTab === "quickTerms" && (
                <QuickTerms
                  resultName={resultName}
                  index={index}
                  activeTab={activeTab}
                  cdata={cdata}
                />
              )}
            </div>
          ) : loading && cardNo === index ? (
            <div className="cardLoader">
              <Spinner className="spinner-card" />
            </div>
          ) : (
            <div className="startview">
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <span className="text-bold">Quick select</span> by clicking on
                the warrant name:
              </div>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <span className="text-bold">Trending warrants</span>
              </div>
              <div id="trendingWarrantTbl">
                <table className="table table-closed">
                  <thead>
                    <tr>
                      <th>Warrant name</th>
                      <th>Bid (MYR)</th>
                      <th>Eff. gearing (x)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hotList?.Trending?.map((item, i) => {
                      return (
                        <tr
                          className={`rowtmpl ${i % 2 === 0 && "striped"}`}
                          key={"12" + i}
                        >
                          <td
                            className="col_tkr"
                            value="2"
                            onClick={() => {
                              updateRic(item);
                            }}
                          >
                            {item.dsply_nmll ? item.dsply_nmll : "-"}
                          </td>
                          <td className="col_bd">
                            {item.BID ? item.BID : "-"}
                          </td>
                          <td className="col_eg">
                            {item.effective_gearing !== null
                              ? Number(item.effective_gearing).toFixed(1)
                              : "-"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <span className="text-bold">Top traded Macquarie warrants</span>
              </div>
              <div id="topTradedWarrantTbl">
                <table className="table table-closed">
                  <thead>
                    <tr>
                      <th>​​Warrant ​​n​ame</th>
                      <th>Bid (MYR)</th>
                      <th>Eff. gearing (x)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hotList?.Toptraded?.map((item, j) => {
                      return (
                        <tr
                          className={`rowtmpl ${j % 2 === 0 && "striped"}`}
                          key={"13" + j}
                        >
                          <td
                            className="col_tkr"
                            value="2"
                            onClick={() => {
                              updateRic(item);
                            }}
                          >
                            {item.dsply_nmll ? item.dsply_nmll : "-"}
                          </td>
                          <td className="col_bd">
                            {item.BID ? item.BID : "-"}
                          </td>
                          <td className="col_eg">
                            {item.effective_gearing !== null
                              ? Number(item.effective_gearing).toFixed(1)
                              : "-"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        id="lm0"
        className="lm-table col-xs-12 md-up-none"
        style={{ position: "relative", width: page > 1 && "50%" }}
      >
        <div id="top_area">
          <div id="dropdown-livematrix">
            {symbol.length>0 && (
              <div style={{ textAlignLast: "center" }}>
                <SelectComp
                  data={symbol}
                  placeholder="Select Warrant"
                  getUnderlyingData={getRic}
                  status="true"
                />
              </div>
            )}
          </div>
          {resultName[index] ? (
            <div className="dataview">
              <div className="ricbar">
                <div
                  id="ticker"
                  className="ricbar-lbl"
                  style={{ textAlign: "center" }}
                >
                  {resultName[index] &&
                    `${resultName[index]?.dsply_nmll} (${resultName[index]?.ticker})`}
                </div>
              </div>
              <div className="btnbar">
                <div className="img-icon-sec">
                  {resultName[index] && resultName[index]?.APPCHEXPRY && (
                    <>
                      <img
                        src="/img/bullet/expiry.fw.png"
                        className="ttip"
                        data-tip
                        data-for={"tooltip_1"}
                      />
                      <ReactTooltip
                        id={"tooltip_1"}
                        delayHide={200}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Near expiry:</strong> Indicates SWs with
                            less than 1 month to expiry. These warrants have
                            higher holding costs due to a higher rate of time
                            decay, so please exercise caution when trading these
                            warrants.
                          </p>
                        </div>
                      </ReactTooltip>
                    </>
                  )}
                  {resultName[index] && resultName[index]?.HIGHLQDY && (
                    <>
                      <img
                        src="/img/bullet/liquidity.fw.png"
                        className="ttip"
                        data-tip
                        data-for={"tooltip_2"}
                      />
                      <ReactTooltip
                        id={"tooltip_2"}
                        delayHide={200}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Highest liquidity:</strong> Highlights the
                            Macquarie SW in each underlying with the largest
                            volume on the current bid and offer, indicating the
                            highest liquidity. This is often also the most
                            actively traded SW.
                          </p>
                        </div>
                      </ReactTooltip>
                    </>
                  )}
                  {resultName[index] && resultName[index]?.MORERESPONSIVE && (
                    <>
                      <img
                        src="/img/bullet/responsive.png"
                        className="ttip"
                        data-tip
                        data-for={"tooltip_3"}
                      />
                      <ReactTooltip
                        id={"tooltip_3"}
                        delayHide={200}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>More responsive:</strong>SWs with this
                            indicator are more responsive to price movements in
                            the underlying.
                          </p>
                        </div>
                      </ReactTooltip>
                    </>
                  )}
                  {resultName[index] &&
                    resultName[index]?.HIGHEFFECTIVEGEARING && (
                      <>
                        <img
                          src="/img/bullet/effGearing.png"
                          className="ttip"
                          data-tip
                          data-for={"tooltip_4"}
                        />
                        <ReactTooltip
                          id={"tooltip_4"}
                          delayHide={200}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>High effective gearing:</strong> SWs with
                              this indicator have relatively higher effective
                              gearing level, meaning the SW price will move at a
                              larger percentage relative to a price movement in
                              the underlying, translating to a higher profit
                              potential but also higher level of risk.
                            </p>
                          </div>
                        </ReactTooltip>
                      </>
                    )}
                  {resultName[index] && resultName[index]?.LOWTIMEDECAY && (
                    <>
                      <img
                        src="/img/bullet/timeDecay.png"
                        className="ttip"
                        data-tip
                        data-for={"tooltip_5"}
                      />
                      <ReactTooltip
                        id={"tooltip_5"}
                        delayHide={200}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Low time decay:</strong> SWs with this
                            indicator have relatively lower time decay, meaning
                            lower holding costs for investors. Generally, all
                            else constant, longer dated SWs have lower time
                            decay than shorter dated SWs and vice versa.
                          </p>
                        </div>
                      </ReactTooltip>
                    </>
                  )}
                  {resultName[index] && resultName[index]?.NEWWARRANT && (
                    <>
                      <img
                        src="/img/bullet/new.png"
                        className="ttip"
                        data-tip
                        data-for={"tooltip_6"}
                      />
                      <ReactTooltip
                        id={"tooltip_6"}
                        delayHide={200}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>New warrant:</strong> SWs with this
                            indicator are relatively new, i.e. listed in the
                            last week.
                          </p>
                        </div>
                      </ReactTooltip>
                    </>
                  )}
                  {resultName[index] && resultName[index]?.TRENDINGWARRANTS && (
                    <>
                      <img
                        src="/img/bullet/hot.fw.png.png"
                        className="ttip"
                        data-tip
                        data-for={"tooltip_7"}
                      />
                      <ReactTooltip
                        id={"tooltip_7"}
                        delayHide={200}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Trending warrants:</strong> The Trending
                            warrants are selected by Macquarie as the daily
                            highlights for investors. The market maker will aim
                            to provide high liquidity for these warrants and, if
                            possible, will try (but is not obliged) to sell to
                            investors queuing at the market maker’s best bid
                            price.
                          </p>
                        </div>
                      </ReactTooltip>
                    </>
                  )}
                  {resultName[index] && resultName[index]?.SOLDOUT && (
                    <>
                      <img
                        src="/img/bullet/soldout.fw.png"
                        className="ttip"
                        data-tip
                        data-for={"tooltip_8"}
                      />
                      <ReactTooltip
                        id={"tooltip_8"}
                        delayHide={200}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>Inventory sold-out:</strong> SWs with this
                            indicator are either sold out or close to being sold
                            out, meaning the market maker may no longer provide
                            an offer price or have widened spreads by raising
                            the offer price. Existing holders are not
                            disadvantaged as the bid price is still maintained
                            at its fair price. However, please exercise caution
                            if you are looking to buy this SW as the market
                            price may be inflated by other investors queuing in
                            the market. Do check the Live Matrix for the
                            warrant’s fair bid price.
                          </p>
                        </div>
                      </ReactTooltip>
                    </>
                  )}
                </div>
                <div className="btnbar2">
                  <div className="viewTechnicalbtn">
                    <Link
                      to={`/tools/recognia?sym=${
                        recogniaData && recogniaData.reco_symbol
                      }&exch=${
                        recogniaData && recogniaData.reco_exchange
                      }&ticker=${recogniaData && recogniaData.ticker}&ric=${
                        recogniaData && recogniaData.ric
                      }`}
                      id="viewTechnical"
                      type="button"
                      target="_blank"
                      className="technicalbtn btn btn-block btn-primary"
                    >
                      Technical chart
                    </Link>
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    <button
                      type="button"
                      className="btn btn-block btn-primary printbtn"
                      onClick={() => handlePrint()}
                    >
                      <img
                        className="printimg"
                        src="/img/bullet/print.png"
                        alt="print live matrix"
                      />
                    </button>
                  </div>
                </div>
              </div>
              {resultName &&
              resultName[index] &&
              resultName[index].dsply_nmll &&
              resultName[index].dsply_nmll.includes("SP500") ? (
                <p
                  id="p-contract"
                  className="p-contract"
                  style={{ textAlign: "center" }}
                >
                  Underlying price refers to the relevant{" "}
                  <b>
                    S&P 500<sup>®</sup>{" "}
                    {
                      resultName[index].future_dsply_name.split(" ")[
                        resultName[index].future_dsply_name.split(" ").length -
                          1
                      ]
                    }
                  </b>{" "}
                  futures contract
                </p>
              ) : resultName &&
                resultName[index] &&
                resultName[index].dsply_nmll &&
                resultName[index].dsply_nmll.includes("DJIA") ? (
                <p
                  id="p-contract"
                  className="p-contract"
                  style={{ textAlign: "center" }}
                >
                  Underlying price refers to{" "}
                  <b>
                    DJIA<sup>®</sup>{" "}
                    {
                      resultName[index].future_dsply_name.split(" ")[
                        resultName[index].future_dsply_name.split(" ").length -
                          1
                      ]
                    }
                  </b>{" "}
                  futures contract
                </p>
              ) : resultName &&
                resultName[index] &&
                resultName[index].dsply_nmll &&
                resultName[index].dsply_nmll.includes("NDX") ? (
                <p
                  id="p-contract"
                  className="p-contract"
                  style={{ textAlign: "center" }}
                >
                  Underlying price refers to{" "}
                  <b>
                    NASDAQ-100<sup>®</sup>{" "}
                    {
                      resultName[index].future_dsply_name.split(" ")[
                        resultName[index].future_dsply_name.split(" ").length -
                          1
                      ]
                    }
                  </b>{" "}
                  futures contract
                </p>
              ) : resultName &&
                resultName[index] &&
                resultName[index].underlying_name &&
                resultName[index].underlying_name.includes("NIKKEI") ? (
                <p
                  id="p-contract"
                  className="p-contract"
                  style={{
                    textAlign: "center",
                    display:
                      resultName[index].future_dsply_name &&
                      resultName[index].future_dsply_name != "-"
                        ? "block"
                        : "none",
                  }}
                >
                  Underlying price refers to{" "}
                  <b>{resultName[index].future_dsply_name}</b> futures contract
                </p>
              ) : (
                <p
                  id="p-contract"
                  className="p-contract"
                  style={{
                    textAlign: "center",
                    display:
                      resultName[index].future_dsply_name &&
                      resultName[index].future_dsply_name != "-"
                        ? "block"
                        : "none",
                  }}
                >
                  Underlying price refers to{" "}
                  <b>{resultName[index].future_dsply_name}</b> futures contract
                </p>
              )}

              {/* {resultName && resultName[index] && resultName[index].dsply_nmll && resultName[index].dsply_nmll.includes("SP") && 
                            <p id="p-contract" className="p-contract" style={{textAlign: "center"}}>Underlying price refers to the relevant <b>S&P 500<sup>®</sup>  {resultName[index].future_dsply_name.split(' ')[resultName[index].future_dsply_name.split(' ').length-1]}</b> futures contract</p>
                        }
                        {resultName && resultName[index] && resultName[index].dsply_nmll && resultName[index].dsply_nmll.includes("DJIA") && 
                            <p id="p-contract" className="p-contract" style={{textAlign: "center"}}>Underlying price refers to <b>DJIA<sup>®</sup>  {resultName[index].future_dsply_name.split(' ')[resultName[index].future_dsply_name.split(' ').length-1]}</b> futures contract</p>
                        }
                        {resultName && resultName[index] && resultName[index].dsply_nmll && resultName[index].dsply_nmll.includes("NDX") && 
                            <p id="p-contract" className="p-contract" style={{textAlign: "center"}}>Underlying price refers to <b>NASDAQ-100<sup>®</sup>  {resultName[index].future_dsply_name.split(' ')[resultName[index].future_dsply_name.split(' ').length-1]}</b> futures contract</p>
                        } */}
              {activeTab === "liveMatrixPlus" && (
                <div className="lmview" ref={myRef}>
                  <div id="lmtablecontainer">
                    {result[index] === null ||
                    result[index] === undefined ||
                    (result[index]?.length === 0 &&
                      loading &&
                      load &&
                      cardNo === index) ? (
                      <div className="spinner-div">
                        <Spinner className="spinner-card" />
                      </div>
                    ) : (
                      ""
                    )}
                    <table id="today_table" className="table lbtable">
                      <thead>
                        <tr>
                          <th width="30%">
                            <span className="th-left">Underlying bid</span>
                          </th>
                          <th width="20%">
                            <span className="th-left">Warrant bid</span>
                          </th>
                          <th width="20%">
                            <span className="th-right">Warrant offer</span>
                          </th>
                          <th width="20%">
                            <span className="th-right">Underlying offer</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {load && result[index]?.length === 0 && (
                          <tr>
                            <td colSpan={6} className="text-content">
                              The matrix for this warrant is currently
                              unavailable. Please call us at +603-2059 8840 for
                              further details.
                            </td>
                            {/* <td colSpan={6} className="text-content">
                                                        There is no matrix available for this
                                                        warrant, as the warrant has no value at
                                                        current levels in the underlying
                                                    </td> */}
                          </tr>
                        )}

                        {result[index] &&
                          result[index].map((d, thirindex) => (
                            <tr
                              className={`${d.classList}`}
                              key={"14" + thirindex}
                            >
                              <td>{Number(d.underlying_bid).toFixed(3)}</td>
                              <td>{Number(d.bid).toFixed(3)}</td>
                              <td>{Number(d.ask).toFixed(3)}</td>
                              <td>{Number(d.underlying_ask).toFixed(3)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div id="last_update">
                    Last update:<span>{lastUpdated}</span>
                  </div>
                </div>
              )}
              {/* Just for Printing */}
              {activeTab !== "liveMatrixPlus" && (
                <div
                  className="lmview"
                  ref={myRef}
                  style={{ position: "absolute", zIndex: -1000 }}
                >
                  <div id="lmtablecontainer">
                    <table id="today_table" className="table lbtable">
                      <thead>
                        <tr>
                          <th width="30%">
                            <span className="th-left">Underlying bid</span>
                          </th>
                          <th width="20%">
                            <span className="th-left">Warrant bid</span>
                          </th>
                          <th width="20%">
                            <span className="th-right">Warrant offer</span>
                          </th>
                          <th width="20%">
                            <span className="th-right">Underlying offer</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {load && result[index]?.length === 0 && (
                          <tr>
                            <td colSpan={6} className="text-content">
                              The matrix for this warrant is currently
                              unavailable. Please call us at +603-2059 8840 for
                              further details.
                            </td>
                            {/* <td colSpan={6} className="text-content">
                                                        There is no matrix available for this
                                                        warrant, as the warrant has no value at
                                                        current levels in the underlying
                                                    </td> */}
                          </tr>
                        )}

                        {result[index] &&
                          result[index].map((d, fourindex) => (
                            <tr
                              className={`${d.classList}`}
                              key={"15" + fourindex}
                            >
                              <td>{Number(d.underlying_bid).toFixed(3)}</td>
                              <td>{Number(d.bid).toFixed(3)}</td>
                              <td>{Number(d.ask).toFixed(3)}</td>
                              <td>{Number(d.underlying_ask).toFixed(3)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div id="last_update">
                    Last update:<span>{lastUpdated}</span>
                  </div>
                </div>
              )}
              {activeTab === "quickTerms" && (
                <QuickTerms
                  resultName={resultName}
                  index={index}
                  activeTab={activeTab}
                  cdata={cdata}
                />
              )}
            </div>
          ) : loading && cardNo === index ? (
            <div className="cardLoader">
              <Spinner className="spinner-card" />
            </div>
          ) : (
            <div className="startview">
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <span className="text-bold">Quick select</span> by clicking on
                the warrant name:
              </div>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <span className="text-bold">Trending warrants</span>
              </div>
              <div id="trendingWarrantTbl">
                <table className="table table-closed">
                  <thead>
                    <tr>
                      <th>​​Warrant ​​n​ame</th>
                      <th>Bid (MYR)</th>
                      <th>Eff. gearing (x)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hotList?.Trending?.map((item, k) => {
                      return (
                        <tr
                          className={`rowtmpl ${k % 2 === 0 && "striped"}`}
                          key={"16" + k}
                        >
                          <td
                            className="col_tkr"
                            value="2"
                            onClick={() => {
                              updateRic(item);
                            }}
                          >
                            {item.dsply_nmll ? item.dsply_nmll : "-"}
                          </td>
                          <td className="col_bd">
                            {item.BID ? item.BID : "-"}
                          </td>
                          <td className="col_eg">
                            {item.effective_gearing !== null
                              ? Number(item.effective_gearing).toFixed(1)
                              : "-"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div style={{ marginTop: "20px", textAlign: "center" }}>
                <span className="text-bold">Top traded Macquarie warrants</span>
              </div>
              <div id="topTradedWarrantTbl">
                <table className="table table-closed">
                  <thead>
                    <tr>
                      <th>​​Warrant ​​n​ame</th>
                      <th>Bid (MYR)</th>
                      <th>Eff. gearing (x)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hotList?.Toptraded?.map((item, l) => {
                      return (
                        <tr
                          className={`rowtmpl ${l % 2 === 0 && "striped"}`}
                          key={"17" + l}
                        >
                          <td
                            className="col_tkr"
                            value="2"
                            onClick={() => {
                              updateRic(item);
                            }}
                          >
                            {item.dsply_nmll ? item.dsply_nmll : "-"}
                          </td>
                          <td className="col_bd">
                            {item.BID ? item.BID : "-"}
                          </td>
                          <td className="col_eg">
                            {item.effective_gearing !== null
                              ? Number(item.effective_gearing).toFixed(1)
                              : "-"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default LiveMatrixCard;
